// import logo from './LogoW.svg';
// import banner from './Banner_W.png';
import all from './All.svg';
import './App.css';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
        <img src={all} className="logo" alt="logo" />
        <Footer/>
    </div>
  );
}

export default App;
