import React from 'react'
import './Footer.css'
function Footer() {
  return (
    <footer className='footer'>
        <p>© 2022 Kouko Software S.L. All rights reserved</p>
    </footer>
  )
}

export default Footer